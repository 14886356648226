<script setup>
import { nextTick, ref, watch } from 'vue';
import ActionList from './ActionList.vue';
import Dropdown from './Dropdown.vue';
import InlineSearch from './InlineSearch.vue';
import { useSelectedSingle } from './useSelected';
import { preserveScroll, triggerChange } from './utils';

defineOptions({
  inheritAttrs: false
});

const props = defineProps({
  url: String,
  id: String,
  name: String,
  value: String,
  placeholder: String,
  required: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  tags: {
    type: Boolean,
    default: false
  },
  tagsPrefixValue: String,
  tagsPrefixResult: String,
  clearable: {
    type: Boolean,
    default: true
  }
});

const emit = defineEmits(['update:value']);

const open = ref(false);
const input = ref(null);
const container = ref(null);

const { selected, selectedValue } = useSelectedSingle(() => props.url, () => props.value);

watch(() => props.disabled, () => {
  if (props.disabled) {
    open.value = false;
  }
});

watch(selectedValue, () => emit('update:value', selectedValue.value));
watch(selectedValue, () => triggerChange(input.value), { flush: 'post'});

function onOpen() {
  if (props.disabled) {
    return;
  }
  open.value = true;
}

async function onClose(viaKeyboard = false) {
  open.value = false;

  if (viaKeyboard) {
    await nextTick();
    preserveScroll(() => container.value.focus());
  }
}

function onSelect(result, viaKeyboard = false) {
  selected.value = result;

  onClose(viaKeyboard);
}

function onClear() {
  if (props.clearable) {
    selected.value = null;
  }
}
</script>

<template>
  <input type="hidden"
         class="rp-select-input"
         ref="input"
         :id="id"
         :name="name"
         :required="required"
         :disabled="disabled"
         :value="selectedValue">

  <div class="rp-select"
       ref="container"
       :tabindex="disabled ? null : 0"
       @click.prevent="onOpen"
       @keydown.enter.prevent="onOpen">

    <div class="rp-select-selection">
      <div class="rp-select-selection-text" :class="{ placeholder: !selected }">
        {{ selected?.text ?? placeholder }}
      </div>

      <ActionList :url="selected?.url"
                  :open="open"
                  :clearable="selected && clearable"
                  :disabled="disabled"
                  @clear="onClear"/>
    </div>
  </div>

  <Teleport to="#rp-selects" :disabled="disabled">
    <Dropdown v-if="open"
              :url="url"
              :tags="tags"
              :tagsPrefixValue="tagsPrefixValue"
              :tagsPrefixResult="tagsPrefixResult"
              :reference="container"
              :selected="selected && [selected] || []"
              :clearable="selected && clearable"
              @select="onSelect"
              @clear="onClear"
              @close="onClose">

      <template #selection="{ search, updateSearch }">
        <InlineSearch :value="search" @update:value="updateSearch"/>
      </template>
    </Dropdown>
  </Teleport>
</template>
