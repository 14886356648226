import $ from 'jquery';

export default class Filter {
  constructor(table, filterId) {
    this.table = table;
    this.filterId = filterId;

    const $filter = $('.rp-filter[data-filter-id=' + filterId + ']');
    if ($filter.exists()) {
      this.filterForm = $filter.data('rp-filter');
      this.values = this.filterForm.getValues();
      this.nonDefault = this.filterForm.isNonDefault();
    }

    this.bindEvents();
  }

  get values() {
    return this._values;
  }

  set values(value) {
    this._values = value;

    this.table.actions.update();
  }

  get nonDefault() {
    return this._nonDefault;
  }

  set nonDefault(value) {
    this._nonDefault = value;
  }

  reset() {
    if (this.filterForm) {
      this.filterForm.reset();
    }
  }

  bindEvents() {
    $(document).on('rp-filter:filter', (event, data) => {
      if (data.id !== this.filterId) {
        return;
      }

      this.values = data.values;
      this.nonDefault = data.nonDefault;
      this.table.load(true);
    });

    $(document).on('rp-filter:reset', (event, data) => {
      if (data.id !== this.filterId) {
        return;
      }

      this.values = data.values;
      this.nonDefault = data.nonDefault;

      if (this.table.search) {
        this.table.search.clear();
      }
    });
  }
}
