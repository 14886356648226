import Dates from '../../core/dates';
import { updateUrl } from '../../utils/functions';

const SELECTOR_STATUS_TODO = '#TODO';
const SELECTOR_STATUS_OVERDUE = '#OVERDUE';

const SUBJECT_CATEGORY_EXTERNAL = 'EXTERNAL';

const ATTR_ACTIVITY_SUBJECT_CATEGORY = 'subject-category';
const PARAM_ACTIVITY_SUBJECT_TYPE = 'type';

class LeasingActivityModal {
  constructor($modal) {
    this.$modal = $modal;

    this.$executionDate = this.$modal.find('#ExecutionDate');

    this.$statusTodo = this.$modal.find(SELECTOR_STATUS_TODO);
    this.$statusOverdue = this.$modal.find(SELECTOR_STATUS_OVERDUE);

    this.$type = this.$modal.find('[name="Type"]');
    this.$subject = this.$modal.find('#Subject');
    this.subjectUrl = this.$subject.attr('data-select-url');

    this.$opportunity = this.$modal.find('#Relation');
    this.$opportunityGroup = this.$opportunity.closest('.form-group');

    this.$forecast = this.$modal.find('#Forecast');
    this.$forecastGroup = this.$forecast.closest('.form-group');

    this.bindEvents();
    this.init();
  }

  bindEvents() {
    this.$executionDate.on('change', () => this.updateStatus());
    this.$type.on('change', () => this.updateSubject());
    this.$subject.on('change', () => this.updateOpportunity());
    this.$opportunity.on('change', () => this.updateForecast());
  }

  init() {
    this.updateStatus();

    this.updateSubject(true);
    this.updateOpportunity(true);
    this.updateForecast();

    // wait for select2 to init and load selected item data, so we can correctly set up fields
    this.$subject.one('rp-select2:loaded', () => this.updateOpportunity());
  }

  updateStatus() {
    const executionDate = Dates.parse(this.$executionDate.val());

    // execution date is set and is in the past
    if (executionDate && executionDate.isBefore(Dates.now())) {
      this.$statusTodo.hidden();
      this.$statusOverdue.hidden(false);
      if (this.$statusTodo.is('.active')) {
        this.$statusOverdue.button('toggle');
      }
    } else {
      this.$statusTodo.hidden(false);
      this.$statusOverdue.hidden();
      if (this.$statusOverdue.is('.active')) {
        this.$statusTodo.button('toggle');
      }
    }
  }

  updateSubject(init = false) {
    const url = updateUrl(this.subjectUrl, { [PARAM_ACTIVITY_SUBJECT_TYPE]: this.$type.filter(':checked').val() });
    this.$subject.attr('data-select-url', url);

    if (!init && this.$subject.val()) {
      this.$subject.val('').trigger('change');
    }
  }

  updateOpportunity(init = false) {
    if (this.getSubjectCategory() === SUBJECT_CATEGORY_EXTERNAL) {
      this.$opportunityGroup.hidden(false).disabled(false);
    } else {
      this.$opportunityGroup.hidden(true).disabled(true);
      if (!init) {
        this.$opportunity.val('').trigger('change');
      }
    }
  }

  updateForecast() {
    const noOpportunity = !this.$opportunity.val();
    this.$forecastGroup.hidden(noOpportunity).disabled(noOpportunity);
  }

  getSubjectCategory() {
    const selectedItem = this.$subject.select2('data')[0];
    if (selectedItem) {
      return selectedItem[ATTR_ACTIVITY_SUBJECT_CATEGORY];
    }

    return null;
  }

}

$(() => {
  $(document).on('rp-modal:loaded', (event, data) => {
    if (data.$root.is('#lsn-activity-modal-form')) {
      new LeasingActivityModal(data.$root);
    }
  });
});