<script setup>
import { debounce } from 'utils/functions';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { preserveScroll } from './utils';

defineOptions({
  inheritAttrs: false
});

const props = defineProps({
  value: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['update:value', 'removeLast']);

const input = ref(null);
const value = ref(props.value);
const focusInput = () => preserveScroll(() => input.value.focus());

watch(() => props.value, () => value.value = props.value);

onMounted(() => {
  focusInput();

  input.value.addEventListener('focusout', focusInput);
});

onBeforeUnmount(() => {
  input.value.removeEventListener('focusout', focusInput);
});

const emitUpdate = debounce(500, () => emit('update:value', value.value));

function onKeyDown(event) {
  if (event.key === 'Backspace' && value.value.length <= 0) {
    emit('removeLast');
  }
}
</script>

<template>
  <span class="rp-select-inline-search" :data-value="value">
    <input type="text" v-model="value" ref="input" @input="emitUpdate" @keydown="onKeyDown">
  </span>
</template>
