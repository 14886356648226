import Fetch from 'core/fetch';
import I18N from 'core/i18n';
import Log from 'core/log';
import Router from 'core/router';
import $ from 'jquery';
import { randomId } from 'utils/functions';
import Viewer from 'viewerjs';

const SELECTOR = '[data-auto-init="single-file-input"]';

function createUpload() {
  return `
    <div class="custom-file file-input-part file-input-upload">
      <input type="file" class="custom-file-input">
      <label class="custom-file-label" data-browse="${I18N.t('upload')}">${I18N.t('upload_choose')}</label>
    </div>
  `;
}

function createProgress() {
  return `
    <span class="form-control file-input-part">
      ${I18N.t('uploading')}
    </span>
  `;
}

function createProgressSpinner() {
  return `
    <span class="input-group-text file-input-part">
      <span class="spinner-border spinner-border-sm" role="status"></span>
    </span>
  `;
}

function createDisplay(resource) {
  return `
    <span class="form-control file-input-part file-input-preview">
      ${resource['file_name']}
    </span>
  `;
}

function createOpenButton(resource) {
  if (!resource['pdf']) {
    return '';
  }

  return `
    <a href="${resource['url']}" target="_blank" role="button" class="rp-icon-btn rp-icon-btn-outline rp-icon-btn-md file-input-part" data-never-disable>
      <rp-icon name="open_in_new" class="icon rp-icon-btn-icon"></rp-icon>
    </a>
  `;
}

function createDownloadButton(resource) {
  return `
    <a href="${resource['download_url']}" role="button" class="rp-icon-btn rp-icon-btn-outline rp-icon-btn-md file-input-part" data-never-disable>
      <rp-icon name="download" class="icon rp-icon-btn-icon"></rp-icon>
    </a>
  `;
}

function createDeleteButton() {
  return `
    <button type="button" class="rp-icon-btn rp-icon-btn-outline rp-icon-btn-md file-input-part file-input-delete">
      <rp-icon name="delete" class="icon rp-icon-btn-icon"></rp-icon>
    </button>
  `;
}

function createPreview(resource) {
  const id = 'thumbnail-' + randomId();
  return `
    <span class="form-control">
      <img id="${id}" src="${resource['thumbnail_url']}" data-original="${resource['url']}" alt="${resource['file_name']}">
      <rp-tooltip target="${id}">${resource['tooltip']}</rp-tooltip>
    </span>
  `;
}

class SingleFileInput {
  constructor($element) {
    this.$element = $element;
    this.resource = null;

    this.$container = this.$element.closest('.input-group');
    this.$addons = this.$container.find('.input-group-append');

    if (this.$element.attr('data-thumbnail') === 'true') {
      this.$thumbnail = this.$element.closest('.input-group-stack').find('.file-thumbnail');
    }

    this.bindEvents();
    this.init();
  }

  bindEvents() {
    this.$container.on('change', '.file-input-upload', event => {
      const input = event.target;

      if (input.files.length > 0) {
        this.upload(input.files[0]);
        input.value = null;
      }
    });

    this.$container.on('click', '.file-input-delete', () => this.delete());

    this.observer = new MutationObserver(() => this.updateState());
    this.observer.observe(this.$element.get(0), {
      attributes: true,
      attributeFilter: ['disabled']
    });
  }

  init() {
    if (this.$element.val()) {
      Fetch.post(Router.get('API_RESOURCE_DETAIL'), { 'resourceids': this.$element.val() })
        .then(response => this.handleResponse(response))
        .catch(error => Log.error(error));
    } else {
      this.update();
    }
  }

  upload(file) {
    this.clear();

    this.$addons.before(createProgress());
    this.$addons.prepend(createProgressSpinner());

    const formData = new FormData();
    formData.append('file', file);

    Fetch.post(Router.get('API_RESOURCE_UPLOAD'), formData)
      .then(response => this.handleResponse(response, true))
      .catch(error => Log.error(error));
  }

  handleResponse(response, triggerChange = false) {
    if (response.ok && response.body['Resources']) {
      this.resource = response.body['Resources'][0];
    } else {
      this.resource = null;
    }
    this.update(triggerChange);
  }

  delete() {
    this.resource = null;
    this.update(true);
  }

  clear() {
    this.$container.find('.file-input-part').remove();
  }

  update(triggerChange = false) {
    this.clear();

    if (this.resource) {
      this.$addons.before(createDisplay(this.resource));
      this.$addons.append(createOpenButton(this.resource));
      this.$addons.append(createDownloadButton(this.resource));
      this.$addons.append(createDeleteButton());
    } else {
      this.$addons.before(createUpload());
    }

    this.updateValue(triggerChange);
    this.updateThumbnail();
    this.updateState();
  }

  updateValue(triggerChange = false) {
    if (this.resource) {
      this.$element.val(this.resource['id']);
    } else {
      this.$element.val('');
    }

    if (triggerChange) {
      this.$element.trigger('change');
      this.$element.trigger('rp-single-file-input:change', {
        resource: this.resource
      });
    }
  }

  updateThumbnail() {
    if (!this.$thumbnail || !this.$thumbnail.exists()) {
      return;
    }

    this.$thumbnail.empty();
    this.$thumbnail.hidden();

    if (this.viewer) {
      this.viewer.destroy();
      this.viewer = null;
    }

    if (this.resource != null && this.resource['thumbnail_url']) {
      this.$thumbnail.append(createPreview(this.resource));
      this.$thumbnail.hidden(false);

      this.viewer = new Viewer(this.$thumbnail.get(0));
    }
  }

  updateState() {
    this.$container.find('.file-input-part').disabled(this.$element.prop('disabled'));
  }
}

function init($root) {
  $root.find(SELECTOR).each(function() {
    const $element = $(this);
    const singleFileInput = new SingleFileInput($element);

    $element.data('rp-single-file-input', singleFileInput);
  });
}

$(()=> {
  init($(document.body));

  $(document).on('rp-partial:loaded', (event, data) => {
    init(data.$root);
  });

  $(document).on('rp-table:loaded', event => {
    init($(event.target));
  });
});
