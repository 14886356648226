import { toValue } from 'vue';
import { ResultItemData } from './types';

function getHandlers(resultsRef, highlightedRef, onSelect, onClose) {
  return {
    'ArrowDown': () => moveHighlighted(resultsRef, highlightedRef, 1),
    'ArrowUp': () => moveHighlighted(resultsRef, highlightedRef, -1),
    'Enter': () => {
      const result = getHighlighted(resultsRef, highlightedRef);
      if (result) {
        onSelect(result);
      }
    },
    'Escape': () => onClose(),
    'Tab': () => onClose()
  };
}

function getHighlighted(resultsRef, highlightedRef) {
  const results = toValue(resultsRef);
  const highlighted = toValue(highlightedRef);

  if (highlighted) {
    return results.find(({ id }) => id === highlighted);
  }
}

function moveHighlighted(resultsRef, highlightedRef, step) {
  const results = toValue(resultsRef);
  const highlighted = toValue(highlightedRef);

  if (highlighted) {
    const highlightedIndex = results.findIndex(({ id }) => id === highlighted);

    if (highlightedIndex >= 0) {
      const target = findHighlightTarget(results, highlightedIndex + step, step);
      if (target) {
        highlightedRef.value = target.id;
      }
    } else {
      initHighlighted(resultsRef, highlightedRef);
    }
  } else {
    initHighlighted(resultsRef, highlightedRef);
  }
}

function findHighlightTarget(results, index, step) {
  while (index >= 0 && index < results.length) {
    const target = results[index];

    if (target instanceof ResultItemData && !target.disabled) {
      return target;
    } else {
      index += step;
    }
  }
}

function initHighlighted(resultsRef, highlightedRef) {
  const results = toValue(resultsRef);

  if (results.length > 0) {
    const target = findHighlightTarget(results, 0, 1);
    if (target) {
      highlightedRef.value = target.id;
    } else {
      highlightedRef.value = null;
    }
  } else {
    highlightedRef.value = null;
  }
}

export function useKeyboard(resultsRef, highlightedRef, onSelect, onClose) {
  const handlers = getHandlers(resultsRef, highlightedRef, onSelect, onClose);

  function onKeyDown(event) {
    const handler = handlers[event.key];
    if (handler) {
      event.preventDefault();
      handler();
    }
  }

  return { onKeyDown };
}
