import Fetch from 'core/fetch';
import Log from 'core/log';
import { computed, ref, toValue, watch } from 'vue';
import { ResultItemData } from './types';

async function fetchSelected(url, ids, selected) {
  const body = {
    init: String(true),
    selected: ids.join(',')
  };

  try {
    const response = await Fetch.post(url, body);

    if (response.ok) {
      return getSelected(ids, response.body.results, selected);
    } else {
      Log.error('Unexpected response status ' + response.status, {
        source: 'VueSelect',
        url: url,
        requestBody: body,
        responseBody: response.body
      });
    }
  } catch (e) {
    Log.error(e);
  }

  return getSelected(ids);
}

function getSelected(ids, results = [], selected = []) {
  const items = [];

  for (const id of ids) {
    const result = results.find(result => result.id === id);
    if (result) {
      items.push(new ResultItemData(result));
      continue;
    }

    const item = selected.find(item => item.id === id);
    if (item) {
      items.push(item);
      continue;
    }

    items.push(new ResultItemData({ id }));
  }

  return items;
}

function useSelect(urlRef, valueRef, parseIds) {
  const selected = ref([]);
  const selectedIds = computed(() => selected.value.map(({ id }) => id));
  const selectedValue = computed(() => selectedIds.value.join(','));

  watch(valueRef, async () => {
    const value = toValue(valueRef)?.trim();
    if (value && value.length > 0) {
      const ids = parseIds(value);
      // assign selected ids as they are (with default '…' as text)
      selected.value = getSelected(ids, [], selected.value);
      // fetch text for selected ids asynchronously
      selected.value = await fetchSelected(toValue(urlRef), ids, selected.value);
    } else {
      selected.value = [];
    }
  }, { immediate: true });

  watch(urlRef, async () => {
    if (selected.value.length > 0) {
      selected.value = await fetchSelected(toValue(urlRef), selectedIds.value, selected.value);
    }
  });

  return { selected, selectedValue };
}

export function useSelectedMulti(urlRef, valueRef) {
  return useSelect(urlRef, valueRef, value => value.split(','));
}

export function useSelectedSingle(urlRef, valueRef) {
  const { selected, selectedValue } = useSelect(urlRef, valueRef, value => [ value ]);

  const selectedSingle = computed({
    get() {
      if (selected.value.length > 0) {
        return selected.value[0];
      }
      return null;
    },
    set(value) {
      selected.value = [];
      if (value) {
        selected.value.push(value);
      }
    }
  });

  return { selected: selectedSingle, selectedValue };
}
