<script setup>
import { HoverActions, IconButton, IconButtonSize, Tooltip } from '@realpadsoftware/realpad-crm-frontend-lib';
import I18N from 'core/i18n';
import { inject } from 'vue';
import { EmailItem } from './Types';

defineProps({
  item: {
    type: EmailItem,
    required: true
  }
});

const createDraft = inject('createDraft');

</script>

<template>
  <!-- TODO rework while moving code to FE lib/app -->
  <HoverActions>
    <div class="rpvue-HoverActions-item" v-if="item.emailReplyAction">
      <IconButton icon="reply" :size="IconButtonSize.SM" @click="createDraft(item.emailReplyAction)">
        <template v-slot:tooltip>
          <Tooltip>{{ I18N.t('email_draft_reply') }}</Tooltip>
        </template>
      </IconButton>
    </div>
    <div class="rpvue-HoverActions-item" v-if="item.emailReplyAllAction">
      <IconButton icon="reply_all" :size="IconButtonSize.SM" @click="createDraft(item.emailReplyAllAction)">
        <template v-slot:tooltip>
          <Tooltip>{{ I18N.t('email_draft_reply_all') }}</Tooltip>
        </template>
      </IconButton>
    </div>
  </HoverActions>
</template>
