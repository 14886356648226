<script setup>
import { HoverActions, IconButton, IconButtonSize, Tooltip } from '@realpadsoftware/realpad-crm-frontend-lib';
import I18N from 'core/i18n';
import { TaskItem } from './Types';
import { useClickActions } from '../../useClickActions';

const props = defineProps({
  item: {
    type: TaskItem,
    required: true
  }
});

const { clickAction, disabled } = useClickActions();

const onCompleteClick = () => clickAction('modal', props.item.taskCompleteAction);
const onCancelClick = () => clickAction('modal', props.item.taskCancelAction);
const onReopenClick = () => clickAction('request', props.item.taskReopenAction, {
  message: I18N.t('crm_task_modal_reopen_text'),
  confirmLabel: I18N.t('crm_task_modal_reopen_button')
});

const onUpdateClick = () => clickAction('modal', props.item.taskUpdateAction);
const onDeleteClick = () => clickAction('request', props.item.taskDeleteAction, {
  level: 'error',
  message: I18N.t('delete_modal_text'),
  confirmLabel: I18N.t('delete')
});
</script>

<template>
  <!-- TODO rework while moving code to FE lib/app -->
  <HoverActions>
    <div class="rpvue-HoverActions-item" v-if="item.taskCompleteAction">
      <IconButton icon="check_circle" :size="IconButtonSize.SM" :disabled="disabled" @click="onCompleteClick">
        <template v-slot:tooltip>
          <Tooltip>{{ I18N.t('crm_task_table_action_complete_tooltip') }}</Tooltip>
        </template>
      </IconButton>
    </div>
    <div class="rpvue-HoverActions-item" v-if="item.taskCancelAction">
      <IconButton icon="cancel" :size="IconButtonSize.SM" :disabled="disabled" @click="onCancelClick">
        <template v-slot:tooltip>
          <Tooltip>{{ I18N.t('crm_task_table_action_cancel_tooltip') }}</Tooltip>
        </template>
      </IconButton>
    </div>
    <div class="rpvue-HoverActions-item" v-if="item.taskReopenAction">
      <IconButton icon="undo" :size="IconButtonSize.SM" :disabled="disabled" @click="onReopenClick">
        <template v-slot:tooltip>
          <Tooltip>{{ I18N.t('crm_task_table_action_reopen_tooltip') }}</Tooltip>
        </template>
      </IconButton>
    </div>
    <div class="rpvue-HoverActions-item" v-if="item.taskUpdateAction">
      <IconButton icon="edit" :size="IconButtonSize.SM" :disabled="disabled" @click="onUpdateClick">
        <template v-slot:tooltip>
          <Tooltip>{{ I18N.t('table_actions_update') }}</Tooltip>
        </template>
      </IconButton>
    </div>
    <div class="rpvue-HoverActions-item" v-if="item.taskDeleteAction">
      <IconButton icon="delete" :size="IconButtonSize.SM" :disabled="disabled" @click="onDeleteClick">
        <template v-slot:tooltip>
          <Tooltip>{{ I18N.t('table_actions_delete') }}</Tooltip>
        </template>
      </IconButton>
    </div>
  </HoverActions>
</template>
