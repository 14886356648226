<script setup>
import I18N from 'core/i18n';
import { computed } from 'vue';

defineOptions({
  inheritAttrs: false
});

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  errorMessage: String,
  empty: {
    type: Boolean,
    default: false
  }
});

const message = computed(() => {
  if (props.loading) {
    return I18N.t('vue_select_message_results_loading');
  }
  if (props.error) {
    return props.errorMessage ?? I18N.t('vue_select_message_results_error');
  }
  if (props.empty) {
    return I18N.t('vue_select_message_results_empty');
  }
  return null;
});
</script>

<template>
  <div v-if="message" class="rp-select-message" :class="{ error: error }">{{ message }}</div>
</template>
