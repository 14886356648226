import { watchDebounced } from '@vueuse/core';
import Fetch from 'core/fetch';
import I18N from 'core/i18n';
import Log from 'core/log';
import { escapeHtml } from 'utils/functions';
import { provide, ref } from 'vue';
import Alerts from '../../../../components/alerts';
import { EmailDraft } from './Types';

const DRAFT_UPDATE_DEBOUNCE_MS = 5000;
const DRAFT_SAVED_DEBOUNCE_MS = 3000;

// TODO rework to use Pinia while moving code to FE lib/app
export function useEmailDraft() {
  const draft = ref(null);
  const draftSaved = ref(false);

  watchDebounced(draft, async (newDraft, oldDraft) => {
    if (newDraft && oldDraft) {
      await updateDraft();
      draftSaved.value = true;
    }
  }, {
    deep: true,
    debounce: DRAFT_UPDATE_DEBOUNCE_MS
  });

  watchDebounced(draftSaved, () => {
    draftSaved.value = false;
  }, {
    debounce: DRAFT_SAVED_DEBOUNCE_MS
  })

  const createDraft = async (url) => {
    closeDraft();

    try {
      const response = await Fetch.post(url);
      const status = response.body?.status;

      if (response.ok && status?.code === 'ok') {
        draft.value = new EmailDraft(response.body?.resource);
        draftSaved.value = false;
      } else {
        if (status?.message) {
          Alerts.error(status?.message);
        } else {
          Log.error('Unexpected response status code ' + status?.code, {
            source: 'ActivityStream',
            url: url,
            responseBody: response.body
          });
        }
      }
    } catch (e) {
      Log.error(e);
    }
  };

  const updateDraft = async () => {
    const value = draft.value;
    const url = value?.updateAction;

    if (url) {
      try {
        const response = await Fetch.postJson(url, {
          subject: value.subject,
          to: value.to,
          cc: value.cc,
          content_text: value.content_text,
          content_html: escapeHtml(value.content_text)?.replace(/\n/g, '<br>'),
          attachment_ids: []
        });
        const status = response.body?.status;

        if (!response.ok || status?.code !== 'ok') {
          if (status?.message) {
            Alerts.error(status?.message);
          }

          Log.error('Unexpected response status ' + response.status, {
            source: 'ActivityStream',
            url: url,
            responseBody: response.body
          });
        }
      } catch (e) {
        Log.error(e);
      }
    }
  };

  const sendDraft = async () => {
    await updateDraft();

    const url = draft.value?.sendAction;
    if (url) {
      try {
        const response = await Fetch.post(url);
        const status = response.body?.status;

        if (response.ok && status?.code === 'ok') {
          Alerts.store('success', I18N.t('email_draft_sent'));
          window.location.reload();
        } else {
          if (status?.message) {
            Alerts.error(status?.message);
          }

          Log.error('Unexpected response status ' + response.status, {
            source: 'ActivityStream',
            url: url,
            responseBody: response.body
          });
        }
      } catch (e) {
        Log.error(e);
      }
    }
  };

  const deleteDraft = async () => {
    const url = draft.value?.deleteAction;

    draft.value = null;
    draftSaved.value = false;

    if (url) {
      try {
        const response = await Fetch.delete(url);
        const status = response.body?.status;

        if (!response.ok || status?.code !== 'ok') {
          if (status?.message) {
            Alerts.error(status?.message);
          }

          Log.error('Unexpected response status ' + response.status, {
            source: 'ActivityStream',
            url: url,
            responseBody: response.body
          });
        }
      } catch (e) {
        Log.error(e);
      }
    }
  };

  const closeDraft = () => {
    updateDraft();

    draft.value = null;
    draftSaved.value = false;
  };

  provide('draftSaved', draftSaved);
  provide('createDraft', createDraft);
  provide('sendDraft', sendDraft);
  provide('deleteDraft', deleteDraft);
  provide('closeDraft', closeDraft);

  return { draft };
}
