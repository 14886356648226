<script setup>
import { onClickOutside } from '@vueuse/core';
import { ref, toRef } from 'vue';
import ActionList from './ActionList.vue';
import ResultList from './ResultList.vue';
import ResultMessage from './ResultMessage.vue';
import { useKeyboard } from './useKeyboard';
import { usePlacement } from './usePlacement';
import { useResults } from './useResults';

const props = defineProps({
  url: String,
  tags: {
    type: Boolean,
    default: false
  },
  tagsPrefixValue: String,
  tagsPrefixResult: String,
  reference: Element,
  selected: {
    type: Array,
    default: []
  },
  clearable: {
    type: Boolean,
    default: true
  }
});

const emit = defineEmits(['select', 'clear', 'close']);

const dropdown = ref(null);
const search = ref('');
const highlighted = ref('');

const { styles, placement } = usePlacement(toRef(props, 'reference'), dropdown);

const { results, onLoadMore, isLoading, isError, errorMessage } = useResults(() => props.url, search,
    () => props.tags, () => props.tagsPrefixValue, () => props.tagsPrefixResult);

const { onKeyDown } = useKeyboard(results, highlighted, onKeyboardSelect, onKeyboardClose);

onClickOutside(dropdown, () => emit('close'));

function onHighlight(result) {
  if (result.disabled) {
    return;
  }

  highlighted.value = result.id;
}

function onSelect(result) {
  emit('select', result);
}

function onKeyboardSelect(result) {
  emit('select', result, true);
}

function onKeyboardClose() {
  emit('close', true);
}
</script>

<template>
  <div class="rp-select-dropdown"
       ref="dropdown"
       :style="styles"
       :class="{ placement: placement }"
       tabindex="0"
       @keydown="onKeyDown">

    <div class="rp-select-selection">
      <slot name="selection" :search="search" :updateSearch="value => search = value"></slot>
      <ActionList :open="true" :clearable="clearable" @clear="$emit('clear')"/>
    </div>

    <div class="rp-select-result-container">
      <ResultList :results="results"
                  :selected="selected"
                  :highlighted="highlighted"
                  @select="onSelect"
                  @highlight="onHighlight"
                  @loadMore="onLoadMore"/>

      <ResultMessage :loading="isLoading" :error="isError" :errorMessage="errorMessage" :empty="results.length <= 0"/>
    </div>
  </div>
</template>
