<script setup>
import { useInfiniteScroll, useResizeObserver } from '@vueuse/core';
import { ref } from 'vue';
import ResultHeader from './ResultHeader.vue';
import ResultItem from './ResultItem.vue';
import { ResultHeaderData, ResultItemData } from './types';

defineOptions({
  inheritAttrs: false
});

defineProps({
  results: {
    type: Array,
    default: []
  },
  selected: {
    type: Array,
    default: []
  },
  highlighted: String
});

const emit = defineEmits(['select', 'highlight', 'loadMore']);

const list = ref(null);

useInfiniteScroll(list, () => emit('loadMore'), { distance: 40, interval: 200 });

useResizeObserver(list, () => {
  if (list.value.scrollHeight > list.value.clientHeight) {
    list.value.classList.add('has-scrollbar');
  } else {
    list.value.classList.remove('has-scrollbar');
  }
});

function getComponent(result) {
  if (result instanceof ResultHeaderData) {
    return ResultHeader;
  }

  if (result instanceof ResultItemData) {
    return ResultItem;
  }

  throw new Error('Unexpected select result type');
}

function isSelected(selected, result) {
  return selected.findIndex(({ id }) => id === result.id) >= 0;
}

function isHighlighted(highlighted, result) {
  return highlighted === result.id;
}
</script>

<template>
  <div class="rp-select-result-list rp-scrollbar" ref="list">
    <component v-for="result in results"
               :key="result.id"
               :is="getComponent(result)"
               :result="result"
               :selected="isSelected(selected, result)"
               :highlighted="isHighlighted(highlighted, result)"
               @select="$emit('select', result)"
               @highlight="$emit('highlight', result)">
    </component>
  </div>
</template>
