<script setup>
import { nextTick, ref, watch } from 'vue';
import ActionList from './ActionList.vue';
import ChipList from './ChipList.vue';
import Dropdown from './Dropdown.vue';
import InlineSearch from './InlineSearch.vue';
import { useSelectedMulti } from './useSelected';
import { preserveScroll, triggerChange } from './utils';

defineOptions({
  inheritAttrs: false
});

const props = defineProps({
  url: String,
  id: String,
  name: String,
  value: String,
  placeholder: String,
  required: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  tags: {
    type: Boolean,
    default: false
  },
  tagsPrefixValue: String,
  tagsPrefixResult: String
});

const emit = defineEmits(['update:value']);

const open = ref(false);
const input = ref(null);
const container = ref(null);

const { selected, selectedValue } = useSelectedMulti(() => props.url, () => props.value);

watch(() => props.disabled, () => {
  if (props.disabled) {
    open.value = false;
  }
});

watch(selectedValue, () => emit('update:value', selectedValue.value));
watch(selectedValue, () => triggerChange(input.value), { flush: 'post'});

function onOpen() {
  if (props.disabled) {
    return;
  }
  open.value = true;
}

async function onClose(viaKeyboard = false) {
  open.value = false;

  if (viaKeyboard) {
    await nextTick();
    preserveScroll(() => container.value.focus());
  }
}

function onSelect(result) {
  if (result.disabled) {
    return;
  }

  const index = selected.value.findIndex(({ id }) => id === result.id);
  if (index < 0) {
    selected.value.push(result);
  } else {
    selected.value.splice(index, 1);
  }
}

function onRemove(result) {
  if (result.disabled) {
    return;
  }

  const index = selected.value.findIndex(({ id }) => id === result.id);
  if (index < 0) {
    return;
  }

  selected.value.splice(index, 1);
}

function onRemoveLast() {
  if (selected.value.length <= 0) {
    return;
  }

  const result = selected.value[selected.value.length - 1];
  if (result.disabled) {
    return;
  }

  selected.value.pop();
}

function onClear() {
  selected.value = selected.value.filter(result => result.disabled);
}
</script>

<template>
  <input type="hidden"
         class="rp-select-input"
         ref="input"
         :id="id"
         :name="name"
         :required="required"
         :disabled="disabled"
         :value="selectedValue">

  <div class="rp-select"
       ref="container"
       :tabindex="disabled ? null : 0"
       @click.prevent="onOpen"
       @keydown.enter.prevent="onOpen">

    <div class="rp-select-selection">
      <ChipList :items="selected" :placeholder="placeholder" :disabled="disabled" @remove="onRemove"/>
      <ActionList :open="open" :clearable="selected.length > 0" :disabled="disabled" @clear="onClear"/>
    </div>
  </div>

  <Teleport to="#rp-selects" :disabled="disabled">
    <Dropdown v-if="open"
              :url="url"
              :tags="tags"
              :tagsPrefixValue="tagsPrefixValue"
              :tagsPrefixResult="tagsPrefixResult"
              :reference="container"
              :selected="selected"
              :clearable="selected.length > 0"
              @select="onSelect"
              @clear="onClear"
              @close="onClose">

      <template #selection="{ search, updateSearch }">
        <ChipList :items="selected" @remove="onRemove">
          <template #inline-search>
            <InlineSearch :value="search" @update:value="updateSearch" @removeLast="onRemoveLast"/>
          </template>
        </ChipList>
      </template>
    </Dropdown>
  </Teleport>
</template>
