// \p{L} matches any kind of letter from any language
// \p{N} matches any kind of numeric character in any script
// space, dash, underscore, dot
const TAG_REGEX = /^[\p{L}\p{N}\s\-_.]+$/u;

export function preserveScroll(callback) {
  const x = window.scrollX;
  const y = window.scrollY;

  callback();

  window.scrollTo(x, y);
}

export function triggerChange(input) {
  input?.dispatchEvent(new Event('change', { bubbles: true }));
}

export function getResultText(text) {
  return `<div class="rp-select-result-text">${text}</div>`;
}

export function isValidTag(tag) {
  return TAG_REGEX.test(tag ?? '');
}
