<script setup>
import { ref, watch } from 'vue';
import { ResultItemData } from './types';
import { getResultText } from './utils';

defineOptions({
  inheritAttrs: false
});

const props = defineProps({
  result: ResultItemData,
  selected: {
    type: Boolean,
    default: false
  },
  highlighted: {
    type: Boolean,
    default: false
  }
});

defineEmits(['select', 'highlight']);

const element = ref(null);

watch(() => props.highlighted, () => {
  if (props.highlighted) {
    element.value.scrollIntoView({
      behavior: 'instant',
      block: 'nearest'
    });
  }
}, { flush: 'post' });

function getHtml(result) {
  return (result.html ?? getResultText(result.text)) + (result.tooltip ?? '');
}
</script>

<template>
  <div class="rp-select-result"
       :class="{ selected: selected, highlighted: highlighted, disabled: result.disabled }"
       ref="element"
       v-html="getHtml(result)"
       @click.prevent="$emit('select')"
       @mouseenter="$emit('highlight')">
  </div>
</template>
