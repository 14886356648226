const DEFAULT_TEXT = '…';

function throwMissingId() {
  throw new Error('Missing ID for select result');
}

export class ResultHeaderData {
  constructor(data) {
    this.id = data.id ?? throwMissingId();
    this.title = data.title ?? DEFAULT_TEXT;
  }
}

export class ResultItemData {
  constructor(data) {
    this.id = data.id ?? throwMissingId();
    this.text = data.text ?? DEFAULT_TEXT;
    this.html = data.html;
    this.url = data.url;
    this.tooltip = data.tooltip;
    this.disabled = data.disabled ?? false;
  }
}
