import $ from 'jquery';
import Dates from '../../core/dates';
import Numbers from '../../core/numbers';

class DealPrepareForm {
  constructor($form) {
    this.rdDeadlineDays = Numbers.parse($form.attr('data-rd-deadline-days'));

    this.$raSignatureDate = $form.find('#ra-signature-date');
    this.$rdDeadline = $form.find('#rd-deadline');

    if (this.$rdDeadline.exists()) {
      this.bindEvents();
    }
  }

  bindEvents() {
    this.$raSignatureDate.on('change', () => {
      const raSignatureDate = Dates.parse(this.$raSignatureDate.val());
      if (raSignatureDate) {
        const rdDeadline = Dates.format(raSignatureDate.add(this.rdDeadlineDays, 'days'));
        this.$rdDeadline.val(rdDeadline).trigger('change').highlight();
      }
    });
  }
}

$(()=> {
  $(document).on('rp-tabs:loaded', (event, data) => {
    const $form = data.$root.find('#deal-prepare-form');
    if ($form.exists()) {
      new DealPrepareForm($form);
    }
  });
});
