import { autoUpdate, flip, offset, size } from '@floating-ui/dom';
import { useFloating } from '@floating-ui/vue';
import { computed, toValue } from 'vue';

const DEFAULT_PADDING = 8;
const NARROW_THRESHOLD = 320;

function getZIndex(element) {
  const style = getComputedStyle(element);
  return parseInt(style.zIndex, 10) || 1;
}

function getMaxZIndex(element) {
  let maxZIndex = 1;

  while (element) {
    maxZIndex = Math.max(maxZIndex, getZIndex(element));
    element = element.parentElement;
  }

  return maxZIndex;
}

function getHeaderStyles() {
  const header = document.body.querySelector('header');
  if (header) {
    const style = getComputedStyle(header);
    return {
      height: parseInt(style.height, 10) || 0,
      zIndex: parseInt(style.zIndex, 10) || 0
    };
  }

  return {
    height: 0,
    zIndex: 0
  };
}

/**
 * Calculates placement (and corresponding styles) for a floating element relative to a reference element.
 * Accounts for page header and z-index of reference element.
 */
export function usePlacement(referenceRef, floatingRef) {
  const zIndex = computed(() => {
    return getMaxZIndex(toValue(referenceRef));
  });

  const padding = computed(() => {
    const headerStyles = getHeaderStyles();

    if (zIndex.value <= headerStyles.zIndex) {
      return {
        top: DEFAULT_PADDING + headerStyles.height,
        left: DEFAULT_PADDING,
        right: DEFAULT_PADDING,
        bottom: DEFAULT_PADDING
      };
    }

    return DEFAULT_PADDING;
  });

  const middleware = computed(() => [
    offset(({ rects }) => -rects.reference.height),
    size({
      apply({ rects, elements }) {
        elements.floating.style.width = `${rects.reference.width}px`;
        elements.floating.classList.toggle('narrow', rects.reference.width < NARROW_THRESHOLD);
      },
      padding: padding.value
    }),
    flip({
      crossAxis: false,
      fallbackStrategy: 'initialPlacement',
      padding: padding.value
    })
  ]);

  const { floatingStyles, placement } = useFloating(referenceRef, floatingRef, {
    placement: 'bottom',
    middleware: middleware,
    whileElementsMounted: autoUpdate
  });

  const styles = computed(() => {
    return {
      ...floatingStyles?.value,
      zIndex: zIndex.value + 1
    }
  });

  return { styles, placement };
}
